var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",style:({ 'margin-top': _vm.statusBarHeight })},[_c('div',{staticClass:"back-button-group",class:{ hidden: _vm.inAttachmentPage }},[_c('button',{staticClass:"exit",class:{ active: !_vm.inChatPage && !_vm.inAttachmentPage },on:{"click":_vm.exitVideo}},[_c('i',{staticClass:"icon-leftarrow"}),_c('span',{on:{"newMessage":function($event){_vm.isHaveNews = false}}},[_vm._v("退出問診")])]),_c('button',{staticClass:"back",class:{ active: _vm.inChatPage },on:{"newMessage":function($event){_vm.isHaveNews = false},"click":_vm.leaveChatPage}},[_c('i',{staticClass:"icon-leftarrow"}),_c('span',[_vm._v("返回")])])]),_c('div',{staticClass:"button-group",class:{ hidden: _vm.inChatPage || _vm.inAttachmentPage }},[_c('button',{class:{
        slash: _vm.setMuteUrl,
        active: !_vm.inChatPage && !_vm.inAttachmentPage,
      },staticStyle:{"--index":"0","padding":"11px"},on:{"click":_vm.switchMute}},[_c('img',{attrs:{"src":require("../../assets/img/mute-false.png"),"alt":""}})]),_c('button',{class:{
        active: !_vm.inChatPage && !_vm.inAttachmentPage,
      },staticStyle:{"--index":"1","padding":"12px"},on:{"click":_vm.switchCamera}},[_c('img',{attrs:{"src":require("../../assets/img/camera_btn.png"),"alt":""}}),(_vm.setCamera)?_c('img',{staticClass:"setSlash",attrs:{"src":require("../../assets/img/slash_red.png"),"alt":""}}):_vm._e()]),_c('button',{class:{ active: !_vm.inChatPage && !_vm.inAttachmentPage },staticStyle:{"--index":"2"},on:{"click":_vm.goToChatPage}},[(!_vm.isHaveNews)?_c('img',{attrs:{"src":require("../../assets/img/chat_btn.png"),"alt":""}}):_vm._e(),(_vm.isHaveNews)?_c('img',{staticStyle:{"padding":"10px"},attrs:{"src":require("../../assets/img/mesg_true.png"),"alt":""}}):_vm._e()]),_c('button',{class:{ active: !_vm.inChatPage && !_vm.inAttachmentPage },staticStyle:{"--index":"3"},on:{"click":function($event){_vm.inAttachmentPage = true}}},[_c('img',{attrs:{"src":require("../../assets/img/attachment_btn.png"),"alt":""}})])]),_c('div',{staticClass:"local-camera",class:{
      active: !_vm.inChatPage && !_vm.inAttachmentPage,
      close: _vm.cameraClosed,
    },attrs:{"id":"local-camera"}},[(_vm.cameraClosed)?_c('div',{staticClass:"close-notes"},[_vm._v("鏡頭已關閉")]):_vm._e()]),(_vm.isShowIcon == true)?_c('div',{staticClass:"remote-camera",class:{ small: _vm.inChatPage },staticStyle:{"background-size":"100px 100px","background-repeat":"no-repeat","background-color":"#50e3c2"},style:({ 'background-image': ("url('" + _vm.doctorAvatar + "')") }),attrs:{"id":"remote-camera"}}):_vm._e(),(_vm.isShowIcon == false)?_c('div',{staticClass:"remote-camera",class:{ small: _vm.inChatPage },staticStyle:{"background-size":"100px 100px","background-repeat":"no-repeat","background-color":"#50e3c2"},attrs:{"id":"remote-camera"}}):_vm._e(),_c('chat',{staticClass:"chat-page",class:{ active: _vm.inChatPage },attrs:{"roomId":_vm.roomId},on:{"newMessage":function($event){_vm.isHaveNews = true}}}),_c('attachment',{attrs:{"active":_vm.inAttachmentPage,"caseId":_vm.caseId},on:{"close":function($event){_vm.inAttachmentPage = false}}}),_c('el-dialog',{staticStyle:{"margin-top":"30%"},attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"80%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("醫生已經關閉問診室，對話結束。"),_c('br'),_c('br'),_c('br'),_vm._v(" 請返回主頁。")]),_c('el-button',{staticClass:"setbtn-check",attrs:{"type":"primary"},on:{"click":_vm.setCheckBack}},[_vm._v("確定")])],1),_c('el-alert',{staticClass:"show_watting",attrs:{"title":_vm.isShowComeIn == false ? '等待醫生進入房間,請稍等' : '問診室已連接',"type":"warning","closable":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }