<template>
  <div class="container" :style="{ 'margin-top': statusBarHeight }">
    <!-- 左上角返回按鈕 -->
    <div class="back-button-group" :class="{ hidden: inAttachmentPage }">
      <button
        class="exit"
        :class="{ active: !inChatPage && !inAttachmentPage }"
        @click="exitVideo"
      >
        <i class="icon-leftarrow"></i>
        <span @newMessage="isHaveNews = false">退出問診</span>
      </button>
      <button
        @newMessage="isHaveNews = false"
        class="back"
        @click="leaveChatPage"
        :class="{ active: inChatPage }"
      >
        <i class="icon-leftarrow"></i>
        <span>返回</span>
      </button>
    </div>
    <!-- 右下角三個按鈕 -->
    <div
      class="button-group"
      :class="{ hidden: inChatPage || inAttachmentPage }"
    >
      <button
        style="--index: 0; padding: 11px"
        :class="{
          slash: setMuteUrl,
          active: !inChatPage && !inAttachmentPage,
        }"
        @click="switchMute"
      >
        <img src="../../assets/img/mute-false.png" alt="" />
      </button>

      <button
        style="--index: 1; padding: 12px"
        :class="{
          active: !inChatPage && !inAttachmentPage,
        }"
        @click="switchCamera"
      >
        <img src="../../assets/img/camera_btn.png" alt="" />
        <img
          v-if="setCamera"
          src="../../assets/img/slash_red.png"
          class="setSlash"
          alt=""
        />
      </button>

      <button
        style="--index: 2"
        :class="{ active: !inChatPage && !inAttachmentPage }"
        @click="goToChatPage"
      >
        <img v-if="!isHaveNews" src="../../assets/img/chat_btn.png" alt="" />
        <img
          v-if="isHaveNews"
          src="../../assets/img/mesg_true.png"
          style="padding: 10px"
          alt=""
        />
      </button>

      <button
        style="--index: 3"
        :class="{ active: !inChatPage && !inAttachmentPage }"
        @click="inAttachmentPage = true"
      >
        <img src="../../assets/img/attachment_btn.png" alt="" />
      </button>
    </div>
    <!-- 用戶攝像頭 -->
    <div
      class="local-camera"
      id="local-camera"
      :class="{
        active: !inChatPage && !inAttachmentPage,
        close: cameraClosed,
      }"
    >
      <div class="close-notes" v-if="cameraClosed">鏡頭已關閉</div>
    </div>
    <div
      class="remote-camera"
      id="remote-camera"
      :class="{ small: inChatPage }"
      style="
        background-size: 100px 100px;
        background-repeat: no-repeat;
        background-color: #50e3c2;
      "
      v-if="isShowIcon == true"
      :style="{ 'background-image': `url('${doctorAvatar}')` }"
    ></div>
    <div
      class="remote-camera"
      id="remote-camera"
      :class="{ small: inChatPage }"
      style="
        background-size: 100px 100px;
        background-repeat: no-repeat;
        background-color: #50e3c2;
      "
      v-if="isShowIcon == false"
    ></div>

    <!-- 文字聊天頁面  :style="{ 'background-image': `url('${doctorAvatar}')` }" -->
    <chat
      class="chat-page"
      :roomId="roomId"
      :class="{ active: inChatPage }"
      @newMessage="isHaveNews = true"
    ></chat>
    <!-- 附件頁面 -->
    <attachment
      :active="inAttachmentPage"
      :caseId="caseId"
      @close="inAttachmentPage = false"
    ></attachment>
    <!-- 退出提示  close-on-click-modal 禁止點擊其他地方-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      style="margin-top: 30%"
      :close-on-click-modal="false"
    >
      <span
        >醫生已經關閉問診室，對話結束。<br /><br /><br />
        請返回主頁。</span
      >
      <el-button class="setbtn-check" type="primary" @click="setCheckBack"
        >確定</el-button
      >
    </el-dialog>

    <el-alert
      class="show_watting"
      :title="
        isShowComeIn == false ? '等待醫生進入房間,請稍等' : '問診室已連接'
      "
      type="warning"
      :closable="false"
    >
    </el-alert>
  </div>
</template>

<script>
import Attachment from "./chatroomComponents/attachment.vue";
import Chat from "./chatroomComponents/chat.vue";
import { chatroomsDetail, chatroomslogin } from "@/api";
import clinicMixin from "./chatroomMixins/clinicMixin";
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store";
export default {
  components: {
    Chat,
    Attachment,
  },
  mixins: [clinicMixin],
  data: function () {
    return {
      statusBarHeight: "0px",
      inChatPage: false,
      inAttachmentPage: false,
      type: "video",
      isHaveNews: false,
      isShowIcon: false,
      timeClick: null,
      isGetUpdate: true,
      setMuteUrl: false,
      setCamera: false,
      dialogVisible: false,
      isShowComeIn: false,
      setTime: null,
    };
  },
  computed: {
    ...mapState({
      userSig: (state) => state.user.userSig,
      userID: (state) => state.user.userId,
    }),
  },

  methods: {
    comein() {
      this.setTimer = setInterval(() => {
        this.isShowComeIn = this.isComeInHourse;
      }, 1000);
    },
    chatroomsDetail,
    chatroomslogin,
    goToChatPage() {
      this.isShowIcon = true;
      this.inChatPage = true;
      this.isHaveNews = false;
      this.rtc.remoteStream && this.rtc.remoteStream.muteVideo();
    },
    leaveChatPage() {
      this.isShowIcon = false;
      this.inChatPage = false;
      this.isHaveNews = false;

      this.rtc.remoteStream && this.rtc.remoteStream.unmuteVideo();
    },
    exitVideo() {
      clearInterval(this.setTimer);

      this.tim.tim.logout();

      // if (this.$store.state.uniappLogin) {
      this.$router.go(-1);
      // return;
      // }

      // this.$router.push({
      //   name: "index",
      //   query: {
      //     setReload: "reLoadingNow",
      //   },
      // }); //进行刷新
      // window.close()
      // console.log(this.tim.tim);
      this.rtc.remoteStream && this.rtc.remoteStream.unmuteVideo();
    },
    backBefore() {
      this.$router.go(-1);
      this.tim.tim.logout();
      this.rtc.remoteStream && this.rtc.remoteStream.unmuteVideo();
    },
    //彈框點擊確認
    setCheckBack() {
      // 結束退出視頻和房間 停止請求
      clearInterval(this.setTimer);
      this.backBefore();
      this.dialogVisible = false;
      return;
    },
    //監聽是否醫生點擊結束
    listenIsDone() {
      if (this.isGetUpdate) {
        const url_ = process.env.VUE_APP_REQUEST_BASE_URL + "chatrooms/detail";
        this.timeClick = setInterval(() => {
          axios
            .get(url_, {
              params: {
                room_number: this.$route.params.roomNumber,
                access_token: store.state.user.token,
              },
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              if (res.data.data.status === 2) {
                //判斷出需要彈框提醒
                this.dialogVisible = true;
                clearInterval(this.timeClick);
                clearInterval(this.setTimer);
                this.timeClick = null;
                this.isGetUpdate = false;
              }
            })
            .catch((err) => {
              return;
            });
        }, 3000);
      }
    },
  },
  created: function () {
    // ios头部内容适配问题
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
    // Case data, room data相关操作
    this.getChatroomDetail(this.$route.params.roomNumber).then((res) => {
      let roomData = res.data;
      this.setChatroomData(roomData);
      // TODO: 后续可能还要加入诊所职员的id
      this.tim.receivedAccount = [roomData.doctor_userId];
      /* 此处的isVideoClosed是指问诊室的视频功能关闭
       * 但是医生/客人仍旧可以进入问诊室内发送
       * 消息。与此处相似的还有
       * "@/views/chatroom/doctorVideoClinic.vue"
       * 参考文档：
       * https://www.teambition.com/project/60989e15945ed0947b35a031/app/5eba5fba6a92214d420a3219/workspaces/60b604927ccb9e0046c71f51/docs/60e8150641cef600017a8c8c
       */
      // TRTC初始化
      if (!this.isVideoClosed) {
        this.TRTCInit();
      }
      if (!this.isChatroomClosed) {
        this.prepareSDK();
      }
    });
    this.listenIsDone();
    this.comein();
  },
};
</script>
<style >
.show_watting {
  position: absolute;
  bottom: 0%;
}
.el-dialog__headerbtn {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  border: none;
  outline: 0;
  display: none;
}
</style>
<style lang="scss" scoped>
.setbtn-check {
  margin-left: 70%;
  margin-top: 20px;
  width: 80px;
  height: 36px;
}
.setSlash {
  position: absolute;
  padding: 12px;
}
.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: $white;
}
.back-button-group {
  position: absolute;
  top: 16px;
  left: 0px;
  height: 48px;
  overflow: hidden;
  z-index: 1000;
  .back,
  .exit {
    color: $green-300;
    font-size: 24px;
    font-weight: $semi-bold;
    background-color: rgba($color: white, $alpha: 0.6);
    padding: 8px;
    transition: all 0.3s ease;
  }
  .exit {
    position: relative;
    left: -100%;
  }
  .back {
    position: absolute;
    left: -100%;
    top: 0;
  }
  .active {
    left: 0;
    transition-delay: 0.2s;
  }
  &.hidden {
    left: -100%;
    transition-property: all;
    transition-delay: 0.3s;
  }
}
.button-group {
  width: 66px;
  height: 242px;
  position: absolute;
  right: 0;
  padding-right: 16px;
  bottom: 66px;
  overflow: hidden;
  z-index: 900;
  @include flexc-between-stretch;
  button {
    background-color: white;
    height: 50px;
    border-radius: 25px;
    color: $green-300;
    transition: 0.3s all calc(0.3s + 0.1s * var(--index)) ease;
    position: relative;
    left: calc(100% + 32px);
    img {
      width: 100%;
    }
  }
  button:first-of-type::after {
    content: "";
    width: 30px;
    height: 2px;
    background-color: $red;
    top: 24px;
    left: 10px;
    position: absolute;
    transform: rotateZ(45deg) scaleX(0);
    transition: 0.3s all ease;
  }
  button.slash:first-of-type::after {
    transform: rotateZ(45deg) scaleX(1);
  }
  .active {
    left: 0;
  }
  &.hidden {
    right: -100%;
    transition-property: all;
    transition-delay: 1s;
  }
}
.local-camera {
  width: 150px;
  height: 150px;
  background-color: black;
  position: absolute;
  top: 0px;
  right: 16px;
  transition: 0.3s all ease;
  z-index: 900;
  transform-origin: top center;
  transform: scaleY(0);
  color: white;
  font-size: 16px;
  font-weight: $regular;
  &.active {
    transform: scaleY(1);
    top: 16px;
  }
  &.close {
    .close-notes {
      height: 24px;
      line-height: 24px;
      text-align: center;
      width: 80px;
      position: absolute;
      top: 63px;
      left: 35px;
      z-index: 100;
    }
  }
}
.chat-page {
  top: 100%;
  &.active {
    top: 0;
  }
}
.remote-camera {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
  transition: 0.3s top ease, 0.3s right ease, 0.3s width ease, 0.3s height ease,
    0.3s z-index ease;
  &.small::v-deep {
    top: 16px;
    right: 16px;
    z-index: 100;
    width: 110px;
    height: 110px;
    div {
      opacity: 0;
    }
  }
}
</style>